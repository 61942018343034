.link-whats {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
}

.link-whats img{
    width: 60px;
    height: 60px;
    transition: 0.2s;
}

.link-whats img:hover{
    transform: scale(1.1);
}

@media screen and (max-width: 480px) { /*SmartPhone*/

    .link-whats img{
        width: 80px;
        height: 80px;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {/*Tablets*/
    
    .link-whats img{
        width: 80px;
        height: 80px;
    }

}

@media screen and (min-width: 769px) and (max-width: 1199px) {/*Small Notebooks*/

    .link-whats img{
        width: 80px;
        height: 80px;
    }

}

@media screen and (min-width: 1200px) {/*Notebook*/

    .link-whats img{
        width: 60px;
        height: 60px;
    }

}