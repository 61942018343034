.main {
    color: white;
    font-size: 16px;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #38254A 38.02%, #661771 90.10%, #38254A 100%);
}

.op-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0px 20px 30px 20px rgba(0, 0, 0, 0.25);
    /*padding: 30px;*/
    padding-bottom: 100px;
}


@media screen and (max-width: 480px) { /*SmartPhone*/

    
    .op-background {

        width: 90%;

    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {/*Tablets*/
    
    .op-background {

        width: 90%;

    }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {/*Small Notebooks*/

    
    .op-background {

        width: 75%;

    }

}

@media screen and (min-width: 1200px) {/*Notebook*/

    
    .op-background {

        width: 60%;

    }

}