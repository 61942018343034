.navbar {
    height: 75px;
    background-color: #38254A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: -webkit-sticky; /* Necessário para funcionar no Safari */
    position: sticky;
    z-index: +1;
    top: 0;
    display: flex;
    flex-direction: row;
}

.divlogo {
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 40px;
}

.links {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    align-items: center;
    justify-content: center;
    margin: auto 40px auto auto;/*top, right, bottom, left*/
}

.links a{
    color: white;
    text-decoration: none;
    text-align: center;
    margin-left: 20px;
}

.links a:hover{
    color: #838383;
}

@media screen and (max-width: 480px) { /*SmartPhone*/

    .links {
        font-size: 1.2rem;
    }

    .navbar {
        height: 120px;
    }

    .logo {
        width: 140px;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {/*Tablets*/
    
    .links {
        font-size: 1.2rem;
    }

    .navbar {
        height: 120px;
    }

    .logo {
        width: 140px;
    }

}

@media screen and (min-width: 769px) and (max-width: 1199px) {/*Small Notebooks*/

    .links {
        font-size: 1.2rem;
    }

    .navbar {
        height: 120px;
    }

    .logo {
        width: 140px;
    }

}

@media screen and (min-width: 1200px) {/*Notebook*/

    .links {
        font-size: 0.813rem;
    }

    .navbar {
        height: 75px;
    }

    .logo {
        width: 100px;
    }

}