.box-examples {
    width: 75%;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.40);
    box-shadow: 0px 4px 15px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    margin-top: 120px;
}

.box-examples img{
    width: 90%;
}

.linha {
    width: 60%;
    margin: 0;
    padding: 0px 0px 35px 0px;
    border-bottom: 2px solid #F17121;
}

.textos {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.textos h1, .textos p {
    width: 40%;
    margin: 10px;
}

.textos h1 {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 2rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.textos p{
    color: #DEDEDE;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 1.25rem;
    font-style: normal;
    line-height: normal;
    padding-top: 7px;
}

.metas {
    width: 75%;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.40);
    box-shadow: 0px 4px 15px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    padding: 40px 0px 40px 0px;
}

.metas > p{
    width: 70%;
    color: #F17121;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.metas-linha {
    width: 65%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.metas-linha img{
    width: 35px;
    height: 35px;
}

.metas-linha p{
    color: #E6E6E6;
    /*font-family: Gothic A1;*/
    font-size:  1.8rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin: 8px;
    white-space: nowrap;
}

@media screen and (max-width: 480px) { /*SmartPhone*/

    .metas {
        width: 95%;
    }

    .metas-linha p{
        font-size:  1.6rem;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {/*Tablets*/
    
    .metas {
        width: 90%;
    }

    .metas-linha p{
        font-size:  1.6rem;
    }

}

@media screen and (min-width: 769px) and (max-width: 1199px) {/*Small Notebooks*/

    .metas {
        width: 85%;
    }

    .metas-linha p{
        font-size:  1.6rem;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1479px){/*Notebook*/

    .metas {
        width: 80%;
    }

    .metas-linha p{
        font-size:  1.3rem;
    }

}

@media screen and (min-width: 1480px) and (max-width: 1599px){/*Notebook*/

    .metas {
        width: 80%;
    }

    .metas-linha p{
        font-size:  1.8rem;
    }

}

@media screen and (min-width: 1600px) {/*Notebook*/

    .metas {
        width: 80%;
    }

    .metas-linha p{
        font-size:  2.1rem;
    }

}