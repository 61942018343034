.banner {
    overflow: auto;
    position: relative;
    height: 700px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;

    background-image: url("../../Images/banner-trafego-pago.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    height: 43.75em;

    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);

    box-shadow: 0px 4px 4px 400px rgba(0, 0, 0, 0.50) inset;
    
}

.title {
    border-radius: 20px;
    background: #F17121;
    box-shadow: 0px 4px 10px 0px rgba(241, 113, 33, 0.25);
    text-align: center;
    width: fit-content;
}

.title h1{
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 3.125rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin: 0;
    padding: 5px 25px 10px 25px;
}

.banner p{
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    /*font-family: Gothic A1;*/
    font-size: 2.25rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    width: 40%;
    margin-top: 20px;
}

.button {
    border-radius: 35px;
    border: 1px solid #E100FF;
    background: #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
    cursor: pointer;
    padding: 10px 25px 13px 25px;
    animation: animaButton 5s linear infinite;
}

.button:hover {
    background: #4d4d4d;
    animation: none;
    transform: scale(1.05);
}

@keyframes animaButton {
  
    0% {
      transform: scale(1.05);
    }
    50%{
      transform: scale(1.0);
    }
    100%{
      transform: scale(1.05);
    }
  
}

.button a{
    color: #FFF;
    text-decoration: none;
    /*font-family: Gothic A1;*/
    font-size: 1.5rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    -webkit-touch-callout: none;  /* iPhone OS, Safari */
    -webkit-user-select: none;    /* Chrome, Safari 3 */
    -khtml-user-select: none;     /* Safari 2 */
    -moz-user-select: none;       /* Firefox */
    -ms-user-select: none;        /* IE10+ */
    user-select: none;
}