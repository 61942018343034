.orcamento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #38254A;
}

.preencha {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preencha h1{
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 2rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin: 100px 0px 10px 0px;
}

.preencha p{
    width: 80%;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

/**/

.form-orcamento {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.40);
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
    width: 85%;
    padding: 20px;

    margin-top: 50px;

}

.form-orcamento input,.form-orcamento textarea,.form-orcamento select{
    border-radius: 12px;
    border: 1px solid rgba(241, 113, 33, 0.70);
    background: rgba(0, 0, 0, 0.70);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    color: white;
    padding: 10px;
    box-sizing: border-box;

    /*color: rgba(222, 222, 222, 0.70);*/
    /*font-family: Gothic A1;*/
    font-size: 20px;
    font-style: normal;
    /*font-weight: 600;*/
    line-height: normal;
    margin: 7.5px;
}

.form-orcamento textarea {
    min-height: 140px;
    resize: vertical;
}

.nome-telefone{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.divbutton {
    width: 100%;
    display: flex;
    justify-content: right;
}

.form-orcamento button{
    width: fit-content;
    padding: 5px 15px 5px 15px;

    border-radius: 12px;
    border: 1px solid #6D6D6D;
    background: #E2E2E2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 7.5px;

    color: #3D3D3D;
    text-align: center;
    /*font-family: Gothic A1;*/
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    cursor: pointer;
}

.desc{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.desc p{
    width: 85%;
    text-align: center;

    color: #FFF;
    text-align: center;
    /*font-family: Gothic A1;*/
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}