.somossert-container {
    width: 80%;
    min-height: 300px;
    margin-top: 120px;
    margin-bottom: 120px;
}

.somossert {
    border-left: 2px solid #E100FF;
    padding: 0px 0px 0px 20px;
}

.somossert h1{
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 2rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-top: 0;
}

#typeit-text {
    color: #DEDEDE;
    text-align: justify;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*font-family: Gothic A1;*/
    font-size: 1.25rem;
    font-style: normal;
    line-height: normal;
    margin-bottom: 0;
}

.quadro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 20px;
    border-radius: 20px;
    border: 1px solid rgba(241, 113, 33, 0.50);
    background: rgba(0, 0, 0, 0.40);
    box-shadow: 0px 4px 4px 0px rgba(241, 113, 33, 0.25);
    padding: 40px 0px 40px 0px;
}

.quadro h1 {
    color: #FFF;
    /*font-family: Gothic A1;*/
    font-size: 1.5rem;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    line-height: normal;
    margin: 0;
    margin-top: 25px;
}

.quadro p {
    color: #DEDEDE;
    text-align: center;
    /*font-family: Gothic A1;*/
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-top: 15px;
    width: 65%;
}

.quadro img {
    width: 100px;
    height: 100px;
}

@media screen and (max-width: 480px) { /*SmartPhone*/

    .quadros {
        width: 80%;
        display: flex;
        flex-direction: row;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {/*Tablets*/
    
    .quadros {
        width: 95%;
        display: flex;
        flex-direction: row;
    }

}

@media screen and (min-width: 769px) and (max-width: 1199px) {/*Small Notebooks*/

    .quadros {
        width: 90%;
        display: flex;
        flex-direction: row;
    }

}

@media screen and (min-width: 1200px) {/*Notebook*/

    .quadros {
        width: 85%;
        display: flex;
        flex-direction: row;
    }

}