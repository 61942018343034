.footer{
    position: relative;
    background: #661771;
    box-shadow: 0px 0px 25px 20px #661771;
    padding: 20px 0px 20px 40px;
    z-index: +1;
}

.footer p{
    width: fit-content;
    color: #9F7AC2;
    /*font-family: Gothic A1;*/
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}